import PropTypes from 'prop-types';
import React from 'react';

import './TableColumnsInfo.scss';

const TableColumnsInfo = ({ name, id, auth, handleClose }) => {
  return (
    <div></div>
  );
};

TableColumnsInfo.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  auth: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired
};

export default TableColumnsInfo;
